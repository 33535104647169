<template>
  <time>
    {{ capitalizeFirstLetter(getTimeString(event)) }}
  </time>
</template>

<script setup lang="ts">
import { getTimeString } from "@/utils/EventHelpers";
import type { Event } from "@/types/Events";
const props = defineProps<{ event: Event }>();
const capitalizeFirstLetter = (text: string) => {
  return text[0].toUpperCase() + text.slice(1);
};
</script>

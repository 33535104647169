<template>
  <section class="self-end space-x-2">
    <button
      class="px-2 py-1 border-1 border-blue-gray-300 text-blue-gray-500 bg-blue-gray-100 rounded-md active:transform active:scale-85"
      :class="{
        'bg-green-50 text-green-500 border-1 border-green-500':
          userId &&
          eventState &&
          eventState.attendance &&
          eventState.attendance[userId] &&
          eventState.attendance[userId].attending === 'definite',
      }"
      @click.stop="toggleAttending('definite')"
    >
      {{ I18nNO.attendingDefinite }}
      {{
        userId &&
        eventState &&
        eventState.countDefinite &&
        eventState.countDefinite > 0
          ? `(${eventState.countDefinite})`
          : ""
      }}
    </button>
    <button
      class="self-end px-2 py-1 border-1 border-blue-gray-300 text-blue-gray-500 bg-blue-gray-100 rounded-md active:transform active:scale-85"
      :class="{
        'bg-green-50 text-green-500 border-1 border-green-500':
          userId &&
          eventState &&
          eventState.attendance &&
          eventState.attendance[userId] &&
          eventState.attendance[userId].attending === 'maybe',
      }"
      @click.stop="toggleAttending('maybe')"
    >
      {{ I18nNO.attendingMaybe }}
      {{
        userId &&
        eventState &&
        eventState.countMaybe &&
        eventState.countMaybe > 0
          ? `(${eventState.countMaybe})`
          : ""
      }}
    </button>
  </section>
</template>
<script setup lang="ts">
import {
  doc,
  serverTimestamp,
  Timestamp,
  setDoc,
  updateDoc,
  collection,
  where,
  query,
  increment,
} from "firebase/firestore";
import type { DocumentData } from "firebase/firestore";
import { computed } from "vue";
import { useDocument, useFirestore } from "vuefire";
import { type Event as CalendarEvent } from "@/types/Events";
import I18nNO from "~/data/I18nNO";
import { server } from "typescript";

type AttendingState = "none" | "definite" | "maybe";
type Attendance = {
  [userId: string]: {
    attending: AttendingState;
    updated: Timestamp;
  };
};

const props = defineProps<{
  calendarEvent: CalendarEvent;
}>();
const userId = useUserState();

const db = useFirestore();
const eventDoc = computed(() => {
  if (props.calendarEvent?.eventId) {
    return doc(db, "events", props.calendarEvent.eventId).withConverter<
      {
        eventDescription: string;
        attendance: Attendance;
        countDefinite: number;
        countMaybe: number;
      },
      DocumentData
    >({
      fromFirestore: (snapshot) => {
        // Here you could do validation with a library like zod
        return snapshot.data(
          // this avoids having `null` while the server timestamp is updating
          { serverTimestamps: "estimate" }
        ) as any;
      },
      toFirestore: (data) => {
        return data;
      },
    });
  } else {
    throw new Error("Missing eventId");
  }
});
const { data: eventState } = useDocument(eventDoc);
const toggleAttending = async (toggledButton: AttendingState) => {
  if (eventState.value) {
    if (eventState.value.attendance[userId.value]) {
      if (eventState.value.attendance[userId.value].attending === "definite") {
        if (toggledButton === "definite") {
          //toggle to 'none', i.e. delete the user's attendance
          const { [userId.value]: _, ...attendance } =
            eventState.value.attendance;
          await updateDoc(eventDoc.value, {
            attendance, // all stored attendances except current user's
            countDefinite: increment(-1),
          });
        } else {
          // must have hit the maybe-button
          const attendance = {
            ...eventState.value.attendance,
          };
          attendance[userId.value].attending = "maybe";
          attendance[userId.value].updated = serverTimestamp() as Timestamp;
          await updateDoc(eventDoc.value, {
            attendance,
            countDefinite: increment(-1),
            countMaybe: increment(1),
          });
        }
      } else if (
        eventState.value.attendance[userId.value].attending === "maybe"
      ) {
        if (toggledButton === "maybe") {
          //toggle to 'none', i.e. delete the user's attendance
          const { [userId.value]: _, ...attendance } =
            eventState.value.attendance;
          await updateDoc(eventDoc.value, {
            attendance, // all stored attendances except current user's
            countMaybe: increment(-1),
          });
        } else {
          // must have hit the definite-button
          const attendance = {
            ...eventState.value.attendance,
          };
          attendance[userId.value].attending = "definite";
          attendance[userId.value].updated = serverTimestamp() as Timestamp;
          await updateDoc(eventDoc.value, {
            attendance,
            countDefinite: increment(1),
            countMaybe: increment(-1),
          });
        }
      }
      // else {
      // attending === 'none', should be unreachable since we delete for 'none'
      // }
    } else {
      // no user attendance registered on this event
      // add user attendance
      if (toggledButton === "definite") {
        await updateDoc(eventDoc.value, {
          attendance: {
            ...eventState.value.attendance,
            [userId.value]: {
              attending: "definite",
              updated: serverTimestamp(),
            },
          },
          countDefinite: increment(1),
          // countMaybe: event.value.countMaybe,
        });
      } else {
        // must have hit the maybe-button
        await updateDoc(eventDoc.value, {
          attendance: {
            ...eventState.value.attendance,
            [userId.value]: {
              attending: "maybe",
              updated: serverTimestamp(),
            },
          },
          // countDefinite: event.value.countDefinite,
          countMaybe: increment(1),
        });
      }
    }
  } else {
    // no event object created yet
    if (props.calendarEvent.eventId) {
      await setDoc(eventDoc.value, {
        eventDescription: getTitleString(props.calendarEvent),
        attendance: {
          [userId.value]: {
            attending: toggledButton,
            updated: serverTimestamp(),
          },
        },
        countDefinite: toggledButton === "definite" ? 1 : 0,
        countMaybe: toggledButton === "maybe" ? 1 : 0,
      });
    } else {
      throw new Error(
        "Trying to create a server-side event, but eventId is missing."
      );
    }
  }
};
</script>

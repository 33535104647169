import type { Event } from "@/types/Events";

export const getTitleString = (event: Event): string =>
  `${event.eventName || event.eventType} | ${getTimeString(event)}`;

export const getTimeString = (event: Event): string => {
  const timestamp = new Date(Date.parse(event.timestamp));
  const timestampEnd = new Date(
    Date.parse(event.timestampEnd || event.timestamp)
  );
  let timeString = "";
  const dayFormat: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "short",
    timeZone: "Europe/Oslo",
  };
  const timeFormat: Intl.DateTimeFormatOptions = {
    ...dayFormat,
    hour: "2-digit",
    minute: "2-digit",
  };
  if (event.timeType === "day-span") {
    timeString = Intl.DateTimeFormat("no-NO", dayFormat)
      .formatRange(timestamp, timestampEnd)
      .replaceAll(",", "");
  } else if (event.timeType === "time-span") {
    timeString = Intl.DateTimeFormat("no-NO", timeFormat)
      .formatRange(timestamp, timestampEnd)
      .replaceAll(",", "");
  } else {
    timeString = `${timestamp.toLocaleString(
      "no-NO",
      dayFormat
    )} ${timestamp.toLocaleString("no-NO", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Europe/Oslo",
    })}`;
  }
  return timeString;
};

export const getDescriptionString = (event: Event): string =>
  `${event.title ? `«${event.title}» \n` : ""}${event.description.replace(
    "\n",
    " \n"
  )}`;

// From https://stackoverflow.com/a/6117889
export const getWeekNumber = (timestamp: Date): [number, number] => {
  // Copy date so don't modify original
  const date = new Date(
    Date.UTC(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate())
  );
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(
    ((date.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
  );
  // Return array of year and week number
  return [date.getUTCFullYear(), weekNo];
};

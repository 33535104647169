<template>
  <section
    class="w-full mx-auto mb-2 px-2 body-font print:bg-white print:columns-3 print:gap-6 print:portrait:columns-2"
  >
    <ol class="relative">
      <li
        v-for="(event, index) in eventsData"
        :key="`event-${index}`"
        class="flex mb-4 <sm:snap-mt-[80px] <sm:snap-mb-[500px] sm:snap-mt-[120px] sm:snap-mb-[300px] break-inside-avoid"
      >
        <input type="hidden" name="eventId" :value="event.eventId" />
        <section v-if="false" class="<sm:hidden sm:block">
          <div>
            {{ getWeekNumber(new Date(Date.parse(event.timestamp)))[1] }}
          </div>
        </section>
        <section
          name="card"
          class="relative flex flex-1 sm:flex-row bg-white dark:bg-black rounded-lg shadow-lg hover:border-1 hover:-m-1px hover:border-green-500 print:shadow-none print:block"
          :class="{ 'flex-col': event.expanded }"
          @click.stop="toggleExpanded(event, $event.target as HTMLElement)"
        >
          <button
            class="absolute z-1 bottom-0 right-0 px-1 text-blue-gray-500 bg-blue-gray-100 rounded-tl-lg rounded-br-lg active:transform active:scale-80 print:hidden"
            @click.stop="
              toggleExpanded(event, $event.target as HTMLElement, true)
            "
          >
            <SvgCircumflex v-if="event.expanded" class="h-6" />
            <SvgCaron v-if="!event.expanded" class="h-6" />
          </button>
          <section name="content" class="relative w-full p-2 print:p-0">
            <figure
              v-if="event.img"
              class="flex flex-col justify-center -m-2 print:-mt-1"
              :class="{
                'sm:order-last float-right <sm:mb-2': event.expanded,
                'float-left mr-2': !event.expanded,
              }"
            >
              <img
                class="object-cover print:w-36 print:h-24"
                :class="{
                  'w-full rounded-t-lg sm:w-120 sm:h-72 sm:m-2 sm:rounded-lg':
                    event.expanded,
                  'w-22 h-22 my-2 ml-2 rounded-lg': !event.expanded,
                }"
                :src="event.img.src"
                :alt="event.img.alt"
              />
              <figcaption
                v-if="event.img.caption"
                class="absolute right-1 py-2 transform rotate-180 write-vertical-right text-right text-xs whitespace-normal text-white bg-dark-500 bg-opacity-25 backdrop-filter backdrop-blur-sm rounded-lg print:hidden"
                :class="{ hidden: !event.expanded }"
              >
                <NuxtLink
                  v-if="event.img.captionHref"
                  :to="event.img.captionHref"
                  :external="true"
                  @click.stop
                  >&copy; {{ event.img.caption }}</NuxtLink
                >
                <span v-else-if="event.img.caption">{{
                  event.img.caption
                }}</span>
              </figcaption>
            </figure>
            <p
              class="text-xl font-medium text-blue-gray-900 dark:text-blue-gray-100 break-words print:m-0 print:text-base"
            >
              {{ event.eventName || event.eventType }}
            </p>
            <TimeField
              class="block mb-3 text-sm font-normal leading-none text-blue-gray-600 dark:text-blue-gray-400 print:text-sm"
              :event="event"
            />
            <p
              v-if="event.title && event.expanded"
              class="text-blue-gray-800 text-lg print:text-sm"
              :class="{
                'mb-2': !(
                  event.biblePassages && event.biblePassages.length > 0
                ),
              }"
            >
              «{{ event.title }}»
            </p>
            <p v-if="event.expanded" class="mb-2">
              <template
                v-for="(biblePassage, index) in event.biblePassages"
                :key="`title-passage-${index}`"
              >
                <NuxtLink
                  v-if="biblePassage.book"
                  :to="`https://bibel.no/Nettbibelen?book=${
                    biblePassage.book
                  }&chapter=${
                    biblePassage.chapterStart ? biblePassage.chapterStart : ''
                  }&verse=${
                    biblePassage.verseStart ? biblePassage.verseStart : 1
                  }&verseto=${
                    biblePassage.verseEnd
                      ? biblePassage.verseEnd
                      : biblePassage.verseStart
                      ? ''
                      : 177
                  }`"
                  :external="true"
                  @click.stop
                  class="inline-block px-2 py-0 mr-2 mb-1 whitespace-nowrap bg-blue-gray-100 dark:bg-blue-gray-900 rounded-md border-1 border-blue-gray-300 text-base print:pl-0 print:pr-1 print:mr-0 print:mb-0 print:text-sm print:bg-transparent print:border-none print:dark:bg-transparent"
                >
                  <span>
                    {{ biblePassage.passage }}
                  </span>
                  <span class="hidden print:inline">;</span>
                  <SvgBook class="inline-block h-6 align-top print:hidden" />
                </NuxtLink>
                <span v-else-if="biblePassage.passage" class="mr-2">{{
                  biblePassage.passage
                }}</span></template
              >
            </p>
            <div
              v-if="
                event.expanded &&
                (event.description ||
                  event.externalLinks ||
                  event.coordinator ||
                  event.organizer)
              "
              class="mb-12"
            >
              <p
                v-if="event.description"
                class="pb-4 text-blue-gray-800 text-base whitespace-pre-wrap print:pb-2"
              >
                {{ event.description }}
              </p>
              <div v-if="event.externalLinks" class="mb-2">
                <p v-for="link in event.externalLinks" class="text-sm">
                  <NuxtLink
                    :to="link.href"
                    :external="true"
                    @click.stop
                    class="text-green-600 hover:text-green-500 focus:text-green-500 active:text-blue-gray-700 print:text-blue-gray-800"
                  >
                    {{ link.label
                    }}<span class="hidden print:inline">: {{ link.href }}</span>
                  </NuxtLink>
                </p>
              </div>
              <p
                v-if="event.coordinator"
                class="text-blue-gray-800 text-sm print:hidden"
              >
                {{ I18nNO.coordinator }}: {{ event.coordinator }}
              </p>
              <p
                v-if="event.organizer"
                class="text-blue-gray-800 text-sm print:hidden"
              >
                {{ I18nNO.organizer }}: {{ event.organizer }}
              </p>
            </div>
            <div v-if="event.expanded" class="absolute bottom-2 print:hidden">
              <div
                v-if="event.expanded && event.showPermalink"
                class="flex mb-1 p-1 border-1 border-blue-gray-300 bg-blue-gray-100 rounded-md break-inside-avoid"
              >
                <div :id="'permaLink_' + event.eventId">
                  {{
                    `https://bede.no/program/${
                      event.eventId
                        ? event.eventId
                        : Date.parse(event.timestamp)
                    }`
                  }}
                </div>
                <SvgCopy class="w-6 h-6 ml-1 text-blue-gray-500" />
              </div>
              <div class="flex flex-grow space-x-2">
                <button
                  class="self-end px-2 py-1 border-1 border-blue-gray-300 text-blue-gray-500 bg-blue-gray-100 rounded-md active:transform active:scale-85"
                  @click.stop="share(event, $event.target as HTMLElement)"
                >
                  <SvgShare class="w-6 h-6" />
                </button>
                <AttendingButton :calendarEvent="event" />
              </div>
            </div>
          </section>
        </section>
      </li>
    </ol>
  </section>
</template>

<script setup lang="ts">
import PlainI18nNO from "@/data/I18nNO.js";
const I18nNO = reactive(PlainI18nNO);
import type Events from "@/types/Events";
import { AllEventsTag, type Event as CalendarEvent } from "@/types/Events";
import {
  getDescriptionString,
  getTitleString,
  getTimeString,
  getWeekNumber,
} from "@/utils/EventHelpers";
import type EventFilters from "@/types/EventFilters";
import AttendingButton from "./AttendingButton.vue";

const props = defineProps<{ events: Events; filters?: EventFilters }>();
const eventsData = computed(() => {
  let include = false;
  return props.events.filter((event, index, calendarEvents) => {
    const previousEvent = calendarEvents[index - 1];
    if (
      previousEvent &&
      previousEvent.calendar != event.calendar &&
      previousEvent.timestamp === event.timestamp &&
      previousEvent.eventName === event.eventName
    ) {
      return false;
    }

    if (props.filters) {
      if (props.filters.search && props.filters.search.length > 0) {
        if (
          [
            getTimeString(event),
            event.eventType,
            event.eventName,
            event.title,
            event.description,
            event.coordinator,
            event.organizer,
          ]
            .join(" ")
            .toLowerCase()
            .includes(props.filters.search.toLowerCase())
        ) {
          include = true;
        }
      } else {
        include = true;
      }

      if (props.filters.eventTypes.get(AllEventsTag)) {
        include = include && true;
      } else {
        include =
          include && props.filters.eventTypes.get(event.eventType) === true;
      }
    } else {
      include = true;
    }
    // if (filters.week) {
    //   if (Date.parse(event.timestamp) < oneWeekAhead) {
    //     return event;
    //   }
    // }
    // if (filters.month) {
    //   if (Date.parse(event.timestamp) < oneMonthAhead) {
    //     return event;
    //   }
    // }
    return include;
  });
});
watch(
  eventsData,
  (newEvents, oldEvents) => {
    if (oldEvents && oldEvents.length > 0) {
      oldEvents[0].expanded = false;
    }
    if (newEvents.length > 0) {
      newEvents[0].expanded = true;
    }
  },
  { immediate: true }
);
const toggleExpanded = (
  event: CalendarEvent,
  target: HTMLElement,
  isButton?: boolean
) => {
  if ((isButton && event.expanded) || !event.expanded) {
    event.expanded = !event.expanded;
  }
  if (event.expanded) {
    target
      .closest("li")
      ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }
};

const share = (event: CalendarEvent, target: HTMLElement) => {
  if (event.showPermalink) {
    event.showPermalink = !event.showPermalink;
    return;
  }
  let fallback = false;
  if (navigator.share) {
    const data = {
      url: `https://bede.no/program/${
        event.eventId ? event.eventId : Date.parse(event.timestamp)
      }`, // Date.parse(): millis UTC
      title: getTitleString(event),
      text: `${getTitleString(event)} \n${getDescriptionString(event)}`,
    };
    navigator.share(data);
  } else if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(
        `https://bede.no/program/${
          event.eventId ? event.eventId : Date.parse(event.timestamp)
        }` // Date.parse(): millis UTC
      )
      .then(() => {
        alert(I18nNO.shareMessage);
      })
      .catch(() => {
        fallback = true;
      });
  } else {
    fallback = true;
  }
  if (fallback) {
    event.showPermalink = !event.showPermalink;
    setTimeout(() => {
      const linkElement = document.getElementById(
        "permaLink_" + event.eventId
      ) as HTMLElement;
      const select = document.createRange();
      select.selectNodeContents(linkElement);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(select);
      }
      // execCommand is deprecated, may not work
      if (document.execCommand("copy")) {
        setTimeout(() => alert(I18nNO.shareMessage));
      }
    });
  }
};
</script>

import type { Book } from "@/types/Events";
const BooksMapCanonical: {
  [Property in Book]: { short: string; long: string };
} = {
  GEN: {
    short: "1 Mos",
    long: "Første Mosebok",
  },
  EXO: {
    short: "2 Mos",
    long: "Andre Mosebok",
  },
  LEV: {
    short: "3 Mos",
    long: "Tredje Mosebok",
  },
  NUM: {
    short: "4 Mos",
    long: "Fjerde Mosebok",
  },
  DEU: {
    short: "5 Mos",
    long: "Femte Mosebok",
  },
  JOS: {
    short: "Jos",
    long: "Josva",
  },
  JDG: {
    short: "Dom",
    long: "Dommerne",
  },
  RUT: {
    short: "Rut",
    long: "Rut",
  },
  "1SA": {
    short: "1 Sam",
    long: "Første Samuelsbok",
  },
  "2SA": {
    short: "2 Sam",
    long: "Andre Samuelsbok",
  },
  "1KI": {
    short: "1 Kong",
    long: "Første Kongebok",
  },
  "2KI": {
    short: "2 Kong",
    long: "Andre Kongebok",
  },
  "1CH": {
    short: "1 Krøn",
    long: "Første Krønikebok",
  },
  "2CH": {
    short: "2 Krøn",
    long: "Andre Krønikebok",
  },
  EZR: {
    short: "Esra",
    long: "Esra",
  },
  NEH: {
    short: "Neh",
    long: "Nehemja",
  },
  EST: {
    short: "Est",
    long: "Ester",
  },
  JOB: {
    short: "Job",
    long: "Job",
  },
  PSA: {
    short: "Sal",
    long: "Salmenes bok",
  },
  PRO: {
    short: "Ordsp",
    long: "Salomos ordspråk",
  },
  ECC: {
    short: "Fork",
    long: "Forkynneren",
  },
  SNG: {
    short: "Høys",
    long: "Høysangen",
  },
  ISA: {
    short: "Jes",
    long: "Jesaja",
  },
  JER: {
    short: "Jer",
    long: "Jeremia",
  },
  LAM: {
    short: "Klag",
    long: "Klagesangene",
  },
  EZK: {
    short: "Esek",
    long: "Esekiel",
  },
  DAN: {
    short: "Dan",
    long: "Daniel",
  },
  HOS: {
    short: "Hos",
    long: "Hosea",
  },
  JOL: {
    short: "Joel",
    long: "Joel",
  },
  AMO: {
    short: "Am",
    long: "Amos",
  },
  OBA: {
    short: "Ob",
    long: "Obadja",
  },
  JON: {
    short: "Jona",
    long: "Jona",
  },
  MIC: {
    short: "Mi",
    long: "Mika",
  },
  NAM: {
    short: "Nah",
    long: "Nahum",
  },
  HAB: {
    short: "Hab",
    long: "Habakkuk",
  },
  ZEP: {
    short: "Sef",
    long: "Sefanja",
  },
  HAG: {
    short: "Hag",
    long: "Haggai",
  },
  ZEC: {
    short: "Sak",
    long: "Sakarja",
  },
  MAL: {
    short: "Mal",
    long: "Malaki",
  },
  MAT: {
    short: "Matt",
    long: "Matteusevangeliet",
  },
  MRK: {
    short: "Mark",
    long: "Markusevangeliet",
  },
  LUK: {
    short: "Luk",
    long: "Lukasevangeliet",
  },
  JHN: {
    short: "Joh",
    long: "Johannesevangeliet",
  },
  ACT: {
    short: "Apg",
    long: "Apostlenes gjerninger",
  },
  ROM: {
    short: "Rom",
    long: "Romerbrevet",
  },
  "1CO": {
    short: "1 Kor",
    long: "Første korinterbrev",
  },
  "2CO": {
    short: "2 Kor",
    long: "Andre korinterbrev",
  },
  GAL: {
    short: "Gal",
    long: "Galaterbrevet",
  },
  EPH: {
    short: "Ef",
    long: "Efeserbrevet",
  },
  PHP: {
    short: "Fil",
    long: "Filipperbrevet",
  },
  COL: {
    short: "Kol",
    long: "Kolosserbrevet",
  },
  "1TH": {
    short: "1 Tess",
    long: "Første tessalonikerbrev",
  },
  "2TH": {
    short: "2 Tess",
    long: "Andre tessalonikerbrev",
  },
  "1TI": {
    short: "1 Tim",
    long: "Første Timoteusbrev",
  },
  "2TI": {
    short: "2 Tim",
    long: "Andre Timoteusbrev",
  },
  TIT: {
    short: "Tit",
    long: "Titus",
  },
  PHM: {
    short: "Filem",
    long: "Filemon",
  },
  HEB: {
    short: "Hebr",
    long: "Hebreerbrevet",
  },
  JAS: {
    short: "Jak",
    long: "Jakobs brev",
  },
  "1PE": {
    short: "1 Pet",
    long: "Første Petersbrev",
  },
  "2PE": {
    short: "2 Pet",
    long: "Andre Petersbrev",
  },
  "1JN": {
    short: "1 Joh",
    long: "Første Johannesbrev",
  },
  "2JN": {
    short: "2 Joh",
    long: "Andre Johannesbrev",
  },
  "3JN": {
    short: "3 Joh",
    long: "Tredje Johannesbrev",
  },
  JUD: {
    short: "Jud",
    long: "Judas' brev",
  },
  REV: {
    short: "Åp",
    long: "Johannes' åpenbaring",
  },
};

export default BooksMapCanonical;

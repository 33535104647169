export default interface Events extends Array<Event> {}

export const AllEventsTag = "Alle aktiviteter";
export type EventType = typeof AllEventsTag | (typeof EventTypes)[number];
export const EventTypes = [
  "Annet",
  "Bibeldagen",
  "Ekstern",
  "Gudstjeneste",
  "Kaffekroken",
  "King Kids",
  "King Teens",
  "Praise",
  "Sangkafe",
  "Seminar",
  "Skolefri",
  "Søndagssamling",
  "Søndagsskolen",
  "Tacofredag",
  "The Chosen",
  "Undervisning og samtale",
  "Ungdomsklubben BD",
  "Upendo",
] as const;

export interface Event {
  eventId?: string;
  calendar?: Calendars;
  timestamp: string;
  timestampEnd?: string;
  timeType?: "timestamp" | "date" | "time-span" | "day-span";
  eventType: EventType;
  eventName?: string;
  title?: string;
  biblePassages?: Array<BiblePassage>;
  description: string;
  coordinator?: string;
  organizer?: string;
  img?: {
    src: string;
    alt?: string;
    caption?: string;
    captionHref?: string;
  };
  externalLinks?: [
    {
      label: string;
      href: string;
    }
  ];
  expanded?: boolean;
  showPermalink?: boolean;
}

export type Calendars = "Bede" | "Bede Ung";

export type BiblePassage = {
  passage: string;
  book?: Book;
  chapterStart?: number;
  chapterEnd?: number;
  verseStart?: number;
  verseEnd?: number;
};

export type Book = (typeof Books)[number];
export const Books = [
  "GEN",
  "EXO",
  "LEV",
  "NUM",
  "DEU",
  "JOS",
  "JDG",
  "RUT",
  "1SA",
  "2SA",
  "1KI",
  "2KI",
  "1CH",
  "2CH",
  "EZR",
  "NEH",
  "EST",
  "JOB",
  "PSA",
  "PRO",
  "ECC",
  "SNG",
  "ISA",
  "JER",
  "LAM",
  "EZK",
  "DAN",
  "HOS",
  "JOL",
  "AMO",
  "OBA",
  "JON",
  "MIC",
  "NAM",
  "HAB",
  "ZEP",
  "HAG",
  "ZEC",
  "MAL",
  "MAT",
  "MRK",
  "LUK",
  "JHN",
  "ACT",
  "ROM",
  "1CO",
  "2CO",
  "GAL",
  "EPH",
  "PHP",
  "COL",
  "1TH",
  "2TH",
  "1TI",
  "2TI",
  "TIT",
  "PHM",
  "HEB",
  "JAS",
  "1PE",
  "2PE",
  "1JN",
  "2JN",
  "3JN",
  "JUD",
  "REV",
] as const;

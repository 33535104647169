<template>
  <section class="w-full mx-auto mb-2 px-2 body-font print:hidden">
    <ol class="relative">
      <li
        class="mb-4 <sm:(snap-mt-[80px] snap-mb-[500px]) sm:(snap-mt-[120px] snap-mb-[300px]) break-inside-avoid"
      >
        <section
          name="card"
          class="relative flex flex-col sm:flex-row bg-white rounded-lg shadow-lg animate-pulse"
        >
          <figure class="relative flex flex-col justify-center sm:order-last">
            <SvgImagePlaceholder
              class="w-full object-cover rounded-t-lg p-2 sm:(w-120 h-72 m-2 rounded-lg) fill-green-400"
              alt="Placeholder"
            />
          </figure>
          <section
            name="content"
            class="relative flex flex-col flex-1 px-2 pt-4"
          >
            <div class="my-2 h-6 w-[30%] bg-blue-gray-400 rounded-sm" />
            <div class="my-2 h-5 w-[80%] bg-blue-gray-400 rounded-sm" />
            <div class="my-2 h-5 w-[76%] bg-blue-gray-400 rounded-sm" />
            <div class="my-2 h-5 w-[83%] bg-blue-gray-400 rounded-sm" />
          </section>
        </section>
      </li>
    </ol>
  </section>
</template>

import BooksToBooksMap from "@/utils/BooksToBooksMap";
import { type BiblePassage } from "@/types/Events";

const regex = new RegExp(
  /^(?<book>\d?\.?[\sA-ZÆØÅa-zæøå']*?[A-ZÆØÅa-zæøå']+)?(?:\skap(?:ittel)?)?\.?\s?(?<chapterStart>\d+)(?:-(?<chapterEnd>\d+)|(?<chapterVerseSeparator>[:,]|\svers)?\s?(?:(?<verseStartWithAdd>\d+)-(?<verseEndWithAdd>\d+)(?<additionalSeparator>[,.])\s?(?<additionalVerseStart>\d+)-(?<additionalVerseEnd>\d+)|(?<verseStart>\d+)-(?<verseEnd>\d+)|(?<verseStartChapterSpan>\d+)-(?<chapterEndChapterSpan>\d+)(?<chapterVerseSeparatorChapterSpan>[:,])\s?(?<verseEndChapterSpan>\d+)|(?<verseStartWithFollow>\d+)(?<follows>f{1,5})|(?<verseStartWithAlso>\d+)[.,]\s?(?<verseAlso>\d+)|(?<verseStartWithoutEnd>\d+)))?$/
);

const parse = (passage: string, eventId?: string): Array<BiblePassage> => {
  const captures = [] as Array<BiblePassage>;
  let lastBook = "";
  passage.split(";").forEach((part, index) => {
    const m = part.trim().match(regex);
    if (m?.groups) {
      const capture = {} as BiblePassage;
      captures.push(capture);
      const book = m.groups.book;
      const chapterStart = parseInt(m.groups.chapterStart);
      let chapterEnd = 0;
      let verseStart = 0;
      let verseEnd = 0;
      const additionalVerseStart = parseInt(m.groups.additionalVerseStart);
      const additionalVerseEnd = parseInt(m.groups.additionalVerseEnd);
      const follows = m.groups.follows;
      const verseAlso = parseInt(m.groups.verseAlso) || 0;
      const expIndex = verseAlso ? 2 * index : index;
      // book part
      // ^(?<book>\d?\.?[\sA-ZÆØÅa-zæøå']*?[A-ZÆØÅa-zæøå']+)?(?: kap(?:ittel)?)?\.?\s?
      if (book) {
        capture.book = BooksToBooksMap[book];
      } else if (lastBook) {
        capture.book = BooksToBooksMap[lastBook];
      } else {
        console.warn(
          `Reference must refer to a book: ${m[0]} |eventId: ${eventId}`
        );
      }
      // chapter part
      // (?<chapterStart>\d+)
      if (chapterStart) {
        capture.chapterStart = chapterStart;
      } else {
        console.warn(
          `Reference must contain a chapter reference: ${m[0]} |eventId: ${eventId}`
        );
      }
      // 1-2
      // -(?<chapterEnd>\d+)|
      if ((chapterEnd = parseInt(m.groups.chapterEnd))) {
        capture.chapterEnd = chapterEnd;
      }
      // 1:2-3:4
      // (?<verseStartChapterSpan>\d+)-(?<chapterEndChapterSpan>\d+)(?<chapterVerseSeparatorChapterSpan>[:,])\s?(?<verseEndChapterSpan>\d+)|
      else if (
        (verseStart = parseInt(m.groups.verseStartChapterSpan)) &&
        (chapterEnd = parseInt(m.groups.chapterEndChapterSpan)) &&
        (verseEnd = parseInt(m.groups.verseEndChapterSpan))
      ) {
        capture.verseStart = verseStart;
        capture.chapterEnd = chapterEnd;
        capture.verseEnd = verseEnd;
      }
      // (?<verseStartWithAdd>\d+)-(?<verseEndWithAdd>\d+)(?<additionalSeparator>[,.])\s?(?<additionalVerseStart>\d+)-(?<additionalVerseEnd>\d+)
      else if (
        (verseStart = parseInt(m.groups.verseStartWithAdd)) &&
        (verseEnd = parseInt(m.groups.verseEndWithAdd)) &&
        additionalVerseStart &&
        additionalVerseEnd
      ) {
        let currentBook = "" as Book;
        if (book) {
          currentBook = BooksToBooksMap[book];
        } else if (lastBook) {
          currentBook = BooksToBooksMap[lastBook];
        }
        capture.verseStart = verseStart;
        capture.verseEnd = verseEnd;
        const additionalCapture = {
          passage,
          book: currentBook,
          chapterStart: chapterStart,
          chapterEnd: 0,
          verseStart: additionalVerseStart,
          verseEnd: additionalVerseEnd,
        };
        captures.push(additionalCapture);
      }
      // 1:2-3
      // (?<verseStart>\d+)-(?<verseEnd>\d+)|
      else if (
        (verseStart = parseInt(m.groups.verseStart)) &&
        (verseEnd = parseInt(m.groups.verseEnd))
      ) {
        capture.verseStart = verseStart;
        capture.verseEnd = verseEnd;
      }
      //1:2f
      //(?<verseStartWithFollow>\d+)(?<follows>f{1,5})|
      else if (
        (verseStart = parseInt(m.groups.verseStartWithFollow)) &&
        follows
      ) {
        const additional = follows.length;
        capture.verseStart = verseStart;
        capture.verseEnd = verseStart + additional;
      }
      //1:2.4
      //(?<verseStartWithAlso>\d+)[.,]\s?(?<verseAlso>\d+)|
      else if (
        (verseStart = parseInt(m.groups.verseStartWithAlso)) &&
        verseAlso
      ) {
        let currentBook = "" as Book;
        if (book) {
          currentBook = BooksToBooksMap[book];
        } else if (lastBook) {
          currentBook = BooksToBooksMap[lastBook];
        }
        capture.verseStart = verseStart;
        capture.verseEnd = 0;
        const additionalCapture = {
          passage,
          book: currentBook,
          chapterStart: chapterStart,
          chapterEnd: 0,
          verseStart: verseAlso,
          verseEnd: 0,
        };
        captures.push(additionalCapture);
      }
      //1:2
      //(?<verseStart>\d+)
      else if ((verseStart = parseInt(m.groups.verseStartWithoutEnd))) {
        capture.verseStart = verseStart;
      }

      chapterEnd = chapterEnd || 0;
      capture.chapterEnd = chapterEnd;

      if (book) {
        lastBook = book;
      } else {
        lastBook = "";
      }
    } else {
      console.warn(
        `Unable to extract passage from: ${passage} |eventId: ${eventId}`
      );
    }
  });

  const chapSep = ",";
  const verseSep = "-";
  captures.forEach((cap) => {
    if (cap.book) {
      let formattedPassage = `${BooksMapCanonical[cap.book].short} ${
        cap.chapterStart
      }`;
      if (cap.chapterEnd && cap.chapterEnd != cap.chapterStart) {
        // consider chapterEnd
        if (!cap.verseStart && !cap.verseEnd) {
          formattedPassage += `${verseSep}${cap.chapterEnd}`;
        } else if (cap.verseStart && cap.verseEnd) {
          formattedPassage += `${chapSep}${cap.verseStart}${verseSep}${cap.chapterEnd}${chapSep}${cap.verseEnd}`;
        } else {
          console.warn(
            `Cannot parse ${JSON.stringify(cap)} |eventId: ${eventId}`
          );
        }
      } else {
        // disregard chapterEnd
        if (cap.verseEnd) {
          if (!cap.verseStart) {
            console.warn(
              `Cannot parse ${JSON.stringify(cap)} |eventId: ${eventId}`
            );
          }
          formattedPassage += `${chapSep}${cap.verseStart}${verseSep}${cap.verseEnd}`;
        } else if (cap.verseStart) {
          formattedPassage += `${chapSep}${cap.verseStart}`;
        }
      }
      cap.passage = formattedPassage;
    }
  });
  return captures;
};
export default parse;

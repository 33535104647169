const BooksToBooksMap: { [key: string]: Book } = {
  "Første Mosebok": "GEN",
  "Første Moses": "GEN",
  "Første Mos": "GEN",
  "1. Mosebok": "GEN",
  "1. Moses": "GEN",
  "1. Mos": "GEN",
  "1.Mosebok": "GEN",
  "1.Moses": "GEN",
  "1.Mos": "GEN",
  "1 Mosebok": "GEN",
  "1 Moses": "GEN",
  "1 Mos": "GEN",
  "1Mosebok": "GEN",
  "1Moses": "GEN",
  "1Mos": "GEN",
  "Andre Mosebok": "EXO",
  "Andre Moses": "EXO",
  "Andre Mos": "EXO",
  "2. Mosebok": "EXO",
  "2. Moses": "EXO",
  "2. Mos": "EXO",
  "2.Mosebok": "EXO",
  "2.Moses": "EXO",
  "2.Mos": "EXO",
  "2 Mosebok": "EXO",
  "2 Moses": "EXO",
  "2 Mos": "EXO",
  "2Mosebok": "EXO",
  "2Moses": "EXO",
  "2Mos": "EXO",
  "Tredje Mosebok": "LEV",
  "Tredje Moses": "LEV",
  "Tredje Mos": "LEV",
  "3. Mosebok": "LEV",
  "3. Moses": "LEV",
  "3. Mos": "LEV",
  "3.Mosebok": "LEV",
  "3.Moses": "LEV",
  "3.Mos": "LEV",
  "3 Mosebok": "LEV",
  "3 Moses": "LEV",
  "3 Mos": "LEV",
  "3Mosebok": "LEV",
  "3Moses": "LEV",
  "3Mos": "LEV",
  "Fjerde Mosebok": "NUM",
  "Fjerde Moses": "NUM",
  "Fjerde Mos": "NUM",
  "4. Mosebok": "NUM",
  "4. Moses": "NUM",
  "4. Mos": "NUM",
  "4.Mosebok": "NUM",
  "4.Moses": "NUM",
  "4.Mos": "NUM",
  "4 Mosebok": "NUM",
  "4 Moses": "NUM",
  "4 Mos": "NUM",
  "4Mosebok": "NUM",
  "4Moses": "NUM",
  "4Mos": "NUM",
  "Femte Mosebok": "DEU",
  "Femte Moses": "DEU",
  "Femte Mos": "DEU",
  "5. Mosebok": "DEU",
  "5. Moses": "DEU",
  "5. Mos": "DEU",
  "5.Mosebok": "DEU",
  "5.Moses": "DEU",
  "5.Mos": "DEU",
  "5 Mosebok": "DEU",
  "5 Moses": "DEU",
  "5 Mos": "DEU",
  "5Mosebok": "DEU",
  "5Moses": "DEU",
  "5Mos": "DEU",
  "Josvas bok": "JOS",
  Josvaboken: "JOS",
  Josva: "JOS",
  Jos: "JOS",
  "Dommernes bok": "JDG",
  Dommerneboken: "JDG",
  Dommerne: "JDG",
  Dom: "JDG",
  "Ruts bok": "RUT",
  Rut: "RUT",
  "Samuels første bok": "1SA",
  "Første Samuels bok": "1SA",
  "Første Samuelsbok": "1SA",
  "Første Samuel": "1SA",
  "1. Samuels bok": "1SA",
  "1. Samuelsbok": "1SA",
  "1. Samuel": "1SA",
  "1.Samuels bok": "1SA",
  "1.Samuelsbok": "1SA",
  "1.Samuel": "1SA",
  "1 Samuels bok": "1SA",
  "1 Samuelsbok": "1SA",
  "1 Samuel": "1SA",
  "1Samuels bok": "1SA",
  "1Samuelsbok": "1SA",
  "1Samuel": "1SA",
  "1. Sam": "1SA",
  "1.Sam": "1SA",
  "1 Sam": "1SA",
  "1Sam": "1SA",
  "Samuels andre bok": "2SA",
  "Andre Samuels bok": "2SA",
  "Andre Samuelsbok": "2SA",
  "Andre Samuel": "2SA",
  "2. Samuels bok": "2SA",
  "2. Samuelsbok": "2SA",
  "2. Samuel": "2SA",
  "2.Samuels bok": "2SA",
  "2.Samuelsbok": "2SA",
  "2.Samuel": "2SA",
  "2 Samuels bok": "2SA",
  "2 Samuelsbok": "2SA",
  "2 Samuel": "2SA",
  "2Samuels bok": "2SA",
  "2Samuelsbok": "2SA",
  "2Samuel": "2SA",
  "2. Sam": "2SA",
  "2.Sam": "2SA",
  "2 Sam": "2SA",
  "2Sam": "2SA",
  "Første Kongebok": "1KI",
  "1. Kongebok": "1KI",
  "1.Kongebok": "1KI",
  "1 Kongebok": "1KI",
  "1Kongebok": "1KI",
  "1. Kong": "1KI",
  "1.Kong": "1KI",
  "1 Kong": "1KI",
  "1Kong": "1KI",
  "Andre Kongebok": "2KI",
  "2. Kongebok": "2KI",
  "2.Kongebok": "2KI",
  "2 Kongebok": "2KI",
  "2Kongebok": "2KI",
  "2. Konge": "2KI",
  "2.Konge": "2KI",
  "2 Konge": "2KI",
  "2Konge": "2KI",
  "Første Krønikebok": "1CH",
  "1. Krønikebok": "1CH",
  "1.Krønikebok": "1CH",
  "1 Krønikebok": "1CH",
  "1Krønikebok": "1CH",
  "1. Krøn": "1CH",
  "1.Krøn": "1CH",
  "1 Krøn": "1CH",
  "1Krøn": "1CH",
  "Andre Krønikebok": "2CH",
  "2. Krønikebok": "2CH",
  "2.Krønikebok": "2CH",
  "2 Krønikebok": "2CH",
  "2Krønikebok": "2CH",
  "2. Krøn": "2CH",
  "2.Krøn": "2CH",
  "2 Krøn": "2CH",
  "2Krøn": "2CH",
  "Esras bok": "EZR",
  Esraboken: "EZR",
  Esra: "EZR",
  "Nehemjas bok": "NEH",
  Nehemjaboken: "NEH",
  Nehemja: "NEH",
  Neh: "NEH",
  "Esters bok": "EST",
  Esterboken: "EST",
  Ester: "EST",
  Est: "EST",
  "Jobs bok": "JOB",
  Job: "JOB",
  "Salmenes bok": "PSA",
  Salmeneboken: "PSA",
  Salmene: "PSA",
  Sal: "PSA",
  "Salomos ordspråk": "PRO",
  Ordspråkene: "PRO",
  Ordspråk: "PRO",
  Ordspr: "PRO",
  Ordsp: "PRO",
  Forkynnerboken: "ECC",
  Forkynneren: "ECC",
  Fork: "ECC",
  Høysangboken: "SNG",
  Høysangene: "SNG",
  Høysangen: "SNG",
  Høys: "SNG",
  Høy: "SNG",
  "Jesajas bok": "ISA",
  Jesajaboken: "ISA",
  Jesaja: "ISA",
  Jes: "ISA",
  Jeremiaboken: "JER",
  "Jeremias bok": "JER",
  Jeremia: "JER",
  Jer: "JER",
  Klagesangene: "LAM",
  Klage: "LAM",
  Klag: "LAM",
  "Esekiels bok": "EZK",
  Esekielboken: "EZK",
  Esekiel: "EZK",
  Esek: "EZK",
  "Daniels bok": "DAN",
  Danielboken: "DAN",
  Daniel: "DAN",
  Dan: "DAN",
  "Hoseas bok": "HOS",
  Hoseaboken: "HOS",
  Hosea: "HOS",
  Hos: "HOS",
  "Joels bok": "JOL",
  Joelboken: "JOL",
  Joel: "JOL",
  "Amos' bok": "AMO",
  "Amos bok": "AMO",
  Amosboken: "AMO",
  Amos: "AMO",
  "Obadjas bok": "OBA",
  Obadjaboken: "OBA",
  Obadja: "OBA",
  Ob: "OBA",
  "Jonas bok": "JON",
  Jonaboken: "JON",
  Jona: "JON",
  Jon: "JON",
  "Mikas bok": "MIC",
  Mikaboken: "MIC",
  Mika: "MIC",
  Mik: "MIC",
  "Nahums bok": "NAM",
  Nahumboken: "NAM",
  Nahum: "NAM",
  Nah: "NAM",
  "Habakkuks bok": "HAB",
  Habakkukboken: "HAB",
  Habakkuk: "HAB",
  Hab: "HAB",
  "Sefanjas bok": "ZEP",
  Sefanjaboken: "ZEP",
  Sefanja: "ZEP",
  Sef: "ZEP",
  "Haggais bok": "HAG",
  Haggaiboken: "HAG",
  Haggai: "HAG",
  Hag: "HAG",
  "Sakarjas bok": "ZEC",
  Sakarjaboken: "ZEC",
  Sakarja: "ZEC",
  Sak: "ZEC",
  "Malakis bok": "MAL",
  Malakiboken: "MAL",
  Malaki: "MAL",
  Mal: "MAL",
  "Evangeliet etter Matteus": "MAT",
  Matteusevangeliet: "MAT",
  "Matteus' evangelium": "MAT",
  "Matteus evangelium": "MAT",
  Matteus: "MAT",
  Matt: "MAT",
  Mat: "MAT",
  "Evangeliet etter Markus": "MRK",
  "Markus' evangelium": "MRK",
  "Markus evangelium": "MRK",
  Markusevangeliet: "MRK",
  Markus: "MRK",
  Mark: "MRK",
  Mar: "MRK",
  "Evangeliet etter Lukas": "LUK",
  "Lukas' evangelium": "LUK",
  "Lukas evangelium": "LUK",
  Lukasevangeliet: "LUK",
  Lukas: "LUK",
  Luk: "LUK",
  Lu: "LUK",
  "Evangeliet etter Johannes": "JHN",
  "Johannes' evangelium": "JHN",
  "Johannes evangelium": "JHN",
  Johannesevangeliet: "JHN",
  Johannes: "JHN",
  Joh: "JHN",
  Jo: "JHN",
  "Apostlenes gjerninger": "ACT",
  Apostelgjerningene: "ACT",
  Apostlene: "ACT",
  Apg: "ACT",
  "Paulus' brev til romerne": "ROM",
  "Paulus brev til romerne": "ROM",
  Romerbrevet: "ROM",
  Romerne: "ROM",
  Rom: "ROM",
  "Paulus' første brev til korinterne": "1CO",
  "Paulus første brev til korinterne": "1CO",
  "Paulus' første korinterbrev": "1CO",
  "Paulus første korinterbrev": "1CO",
  "Første korinterbrev": "1CO",
  "1. Korinterbrev": "1CO",
  "1.Korinterbrev": "1CO",
  "1 Korinterbrev": "1CO",
  "1Korinterbrev": "1CO",
  "Første korinter": "1CO",
  "1. Korinter": "1CO",
  "1.Korinter": "1CO",
  "1 Korinter": "1CO",
  "1Korinter": "1CO",
  "Første Kor": "1CO",
  "1. Kor": "1CO",
  "1.Kor": "1CO",
  "1 Kor": "1CO",
  "1Kor": "1CO",
  "Paulus' andre brev til korinterne": "2CO",
  "Paulus andre brev til korinterne": "2CO",
  "Paulus' andre korinterbrev": "2CO",
  "Paulus andre korinterbrev": "2CO",
  "Andre korinterbrev": "2CO",
  "2. Korinterbrev": "2CO",
  "2.Korinterbrev": "2CO",
  "2 Korinterbrev": "2CO",
  "2Korinterbrev": "2CO",
  "Andre korinter": "2CO",
  "2. Korinter": "2CO",
  "2.Korinter": "2CO",
  "2 Korinter": "2CO",
  "2Korinter": "2CO",
  "Andre Kor": "2CO",
  "2. Kor": "2CO",
  "2.Kor": "2CO",
  "2 Kor": "2CO",
  "2Kor": "2CO",
  "Paulus' brev til galaterne": "GAL",
  "Paulus brev til galaterne": "GAL",
  Galaterbrevet: "GAL",
  Galaterne: "GAL",
  Gal: "GAL",
  "Paulus' brev til efeserne": "EPH",
  "Paulus brev til efeserne": "EPH",
  Efeserbrevet: "EPH",
  Efeserne: "EPH",
  Ef: "EPH",
  "Paulus' brev til filipperne": "PHP",
  "Paulus brev til filipperne": "PHP",
  Filipperbrevet: "PHP",
  Filipperne: "PHP",
  Fil: "PHP",
  "Paulus' brev til kolosserne": "COL",
  "Paulus brev til kolosserne": "COL",
  Kolosserbrevet: "COL",
  Kolosserne: "COL",
  Kol: "COL",
  "Paulus' første brev til tessalonikerne": "1TH",
  "Paulus første brev til tessalonikerne": "1TH",
  "Paulus' første tessalonikerbrev": "1TH",
  "Paulus første tessalonikerbrev": "1TH",
  "Første tessalonikerbrev": "1TH",
  "1. Tessalonikerbrev": "1TH",
  "1.Tessalonikerbrev": "1TH",
  "1 Tessalonikerbrev": "1TH",
  "1Tessalonikerbrev": "1TH",
  "1. Tessaloniker": "1TH",
  "1.Tessaloniker": "1TH",
  "1 Tessaloniker": "1TH",
  "1Tessaloniker": "1TH",
  "1. Tess": "1TH",
  "1.Tess": "1TH",
  "1 Tess": "1TH",
  "1Tess": "1TH",
  "Paulus' andre brev til tessalonikerne": "2TH",
  "Paulus andre brev til tessalonikerne": "2TH",
  "Paulus' andre tessalonikerbrev": "2TH",
  "Paulus andre tessalonikerbrev": "2TH",
  "Andre tessalonikerbrev": "2TH",
  "2. Tessalonikerbrev": "2TH",
  "2.Tessalonikerbrev": "2TH",
  "2 Tessalonikerbrev": "2TH",
  "2Tessalonikerbrev": "2TH",
  "2. Tessaloniker": "2TH",
  "2.Tessaloniker": "2TH",
  "2 Tessaloniker": "2TH",
  "2Tessaloniker": "2TH",
  "2. Tess": "2TH",
  "2.Tess": "2TH",
  "2 Tess": "2TH",
  "2Tess": "2TH",
  "Paulus' første brev til Timoteus": "1TI",
  "Paulus første brev til Timoteus": "1TI",
  "Paulus' første Timoteusbrev": "1TI",
  "Paulus første Timoteusbrev": "1TI",
  "Første Timoteusbrev": "1TI",
  "1. Timoteusbrev": "1TI",
  "1.Timoteusbrev": "1TI",
  "1 Timoteusbrev": "1TI",
  "1Timoteusbrev": "1TI",
  "1. Timoteus": "1TI",
  "1.Timoteus": "1TI",
  "1 Timoteus": "1TI",
  "1Timoteus": "1TI",
  "1. Tim": "1TI",
  "1.Tim": "1TI",
  "1 Tim": "1TI",
  "1Tim": "1TI",
  "Paulus' andre brev til Timoteus": "2TI",
  "Paulus andre brev til Timoteus": "2TI",
  "Paulus' andre Timoteusbrev": "2TI",
  "Paulus andre Timoteusbrev": "2TI",
  "Andre Timoteusbrev": "2TI",
  "2. Timoteusbrev": "2TI",
  "2.Timoteusbrev": "2TI",
  "2 Timoteusbrev": "2TI",
  "2Timoteusbrev": "2TI",
  "2. Timoteus": "2TI",
  "2.Timoteus": "2TI",
  "2 Timoteus": "2TI",
  "2Timoteus": "2TI",
  "2. Tim": "2TI",
  "2.Tim": "2TI",
  "2 Tim": "2TI",
  "2Tim": "2TI",
  "Paulus' brev til Titus": "TIT",
  "Paulus brev til Titus": "TIT",
  "Titus brevet": "TIT",
  Titusbrevet: "TIT",
  "Titus' brev": "TIT",
  "Titus brev": "TIT",
  Titus: "TIT",
  Tit: "TIT",
  "Paulus' brev til Filemon": "PHM",
  "Paulus brev til Filemon": "PHM",
  "Filemon brevet": "PHM",
  Filemonbrevet: "PHM",
  "Filemons brev": "PHM",
  Filemon: "PHM",
  Filem: "PHM",
  File: "PHM",
  Film: "PHM",
  Flm: "PHM",
  "Paulus' brev til Hebreerne": "HEB",
  "Paulus brev til Hebreerne": "HEB",
  "Brevet til Hebreerne": "HEB",
  Hebreerbrevet: "HEB",
  Hebreerne: "HEB",
  Hebr: "HEB",
  Heb: "HEB",
  Jakobsbrevet: "JAS",
  "Jakobs brev": "JAS",
  Jakob: "JAS",
  Jak: "JAS",
  "Peters første brev": "1PE",
  "Første Petersbrev": "1PE",
  "1. Petersbrev": "1PE",
  "1.Petersbrev": "1PE",
  "1 Petersbrev": "1PE",
  "1Petersbrev": "1PE",
  "1. Peter": "1PE",
  "1.Peter": "1PE",
  "1 Peter": "1PE",
  "1Peter": "1PE",
  "1. Pet": "1PE",
  "1.Pet": "1PE",
  "1 Pet": "1PE",
  "1Pet": "1PE",
  "Peters andre brev": "2PE",
  "Andre Petersbrev": "2PE",
  "2. Petersbrev": "2PE",
  "2.Petersbrev": "2PE",
  "2 Petersbrev": "2PE",
  "2Petersbrev": "2PE",
  "2. Peter": "2PE",
  "2.Peter": "2PE",
  "2 Peter": "2PE",
  "2Peter": "2PE",
  "2. Pet": "2PE",
  "2.Pet": "2PE",
  "2 Pet": "2PE",
  "2Pet": "2PE",
  "Johannes' første brev": "1JN",
  "Johannes første brev": "1JN",
  "Første Johannesbrev": "1JN",
  "1. Johannesbrev": "1JN",
  "1.Johannesbrev": "1JN",
  "1 Johannesbrev": "1JN",
  "1Johannesbrev": "1JN",
  "1. Johannes": "1JN",
  "1.Johannes": "1JN",
  "1 Johannes": "1JN",
  "1Johannes": "1JN",
  "1. Joh": "1JN",
  "1.Joh": "1JN",
  "1 Joh": "1JN",
  "1Joh": "1JN",
  "Johannes' andre brev": "2JN",
  "Johannes andre brev": "2JN",
  "Andre Johannesbrev": "2JN",
  "2. Johannesbrev": "2JN",
  "2.Johannesbrev": "2JN",
  "2 Johannesbrev": "2JN",
  "2Johannesbrev": "2JN",
  "2. Johannes": "2JN",
  "2.Johannes": "2JN",
  "2 Johannes": "2JN",
  "2Johannes": "2JN",
  "2. Joh": "2JN",
  "2.Joh": "2JN",
  "2 Joh": "2JN",
  "2Joh": "2JN",
  "Johannes' tredje brev": "3JN",
  "Johannes tredje brev": "3JN",
  "Tredje Johannesbrev": "3JN",
  "3. Johannesbrev": "3JN",
  "3.Johannesbrev": "3JN",
  "3 Johannesbrev": "3JN",
  "3Johannesbrev": "3JN",
  "3. Johannes": "3JN",
  "3.Johannes": "3JN",
  "3 Johannes": "3JN",
  "3Johannes": "3JN",
  "3. Joh": "3JN",
  "3.Joh": "3JN",
  "3 Joh": "3JN",
  "3Joh": "3JN",
  "Judas' brev": "JUD",
  "Judas brev": "JUD",
  Judasbrevet: "JUD",
  Judas: "JUD",
  Jud: "JUD",
  "Johannes' åpenbaring": "REV",
  "Johannes åpenbaring": "REV",
  Åpenbaringen: "REV",
  Åp: "REV",
};

export default BooksToBooksMap;
